<template>
  <div data-cy="company-users-view">
    <TabTitle class="mb-4" icon="fa-user-gear" action-position="right">
      <template>Roles</template>
      <template #sub-title>List of roles that belong to this company.</template>
      <template #actions>
        <router-link class="btn btn-primary" :to="{ path: `/company/roles/create` }" data-cy="add-role-btn">
          <i class="fa fa-plus mr-1"></i> Add Role
        </router-link>
      </template>
    </TabTitle>
    <div class="block-content block-content-full">
      <DataTable
        :items="roles"
        :total="rolesTotal"
        :loading="loadingAction.listRoles"
        :columns="columns"
        :page-size="20"
        :fetch-items="refresh"
        default-sort-key="name"
        :default-sort-direction="1"
      >
        <template v-slot:systemOnly="{ data }">
          <span v-if="data.systemOnly" class="badge badge-info">System</span>
          <span v-else></span>
        </template>
        <template v-slot:actions="{ data }">
          <div class="d-flex">
            <router-link
              v-if="!data.systemOnly"
              class="btn btn-sm btn-primary"
              :to="{
                name: 'company-roles-edit',
                params: { roleId: data._id }
              }"
              >Manage</router-link
            >
            <button v-if="!data.systemOnly" class="btn btn-sm btn-danger ml-2" @click="modals.remove = data">Remove</button>
          </div>
        </template>
      </DataTable>
      <ConfirmModal
        id="remove"
        :open="!!modals.remove"
        title="Remove Role"
        :text="`Please confirm you would like to remove role:<br/><br/><strong>${modals.remove.name}</strong>`"
        @close="modals.remove = false"
        @submit="onRemoveRole"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ConfirmModal from '@/components/ConfirmModal';
import DataTable from '@/components/DataTable';
import TabTitle from '@/components/base/TabTitle';

export default {
  name: 'CompanyRolesList',
  components: {
    ConfirmModal,
    DataTable,
    TabTitle
  },
  data() {
    return {
      modals: {
        remove: false
      }
    };
  },
  computed: {
    ...mapGetters({
      company: 'company/company',
      roles: 'role/roles',
      rolesTotal: 'role/total',
      loadingAction: 'role/loadingAction',
      errorAction: 'role/errorAction'
    }),
    columns() {
      return [
        { name: 'Name', code: 'name', search: true },
        { name: 'System', code: 'systemOnly' },
        { name: 'Permissions', code: 'permissionIds.length', noSort: true },
        { name: 'Description', code: 'description', search: true },
        { name: 'Actions', code: 'actions' }
      ];
    }
  },
  methods: {
    ...mapActions({
      listRoles: 'role/list',
      removeRole: 'role/remove'
    }),
    refresh({ data }) {
      this.listRoles({ id: this.$auth.companyId, data });
    },
    onClickRemoveRole(role) {
      this.modals.remove = role;
    },
    async onRemoveRole() {
      const removeRole = await this.removeRole({ id: this.modals.remove._id });

      if (removeRole?._id) {
        this.$toasted.success('Role removed successfully!', { position: 'bottom-right', duration: 3000 });
        this.refresh();
      } else {
        this.$toasted.error('Role was not removed successfully.', { position: 'bottom-right', duration: 3000 });
      }

      this.modals.remove = false;
    }
  }
};
</script>
